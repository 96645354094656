import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://prod.4-play.io",
});

export const BASE_URL = "https://prod.4-play.io";

export const MEDIA_URL = "https://4play.s3.il-central-1.amazonaws.com";

export default axios;

// import Axios from "axios";

// const axios = Axios.create({
//   baseURL: "https://dev.4-play.io",
// });

// export const BASE_URL = "https://dev.4-play.io";

// // export const MEDIA_URL = "https://4play.s3.il-central-1.amazonaws.com";
// export const MEDIA_URL = "https://4play-dev.s3.il-central-1.amazonaws.com";

// export default axios;
