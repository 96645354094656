export interface User {
  id?: string;
  name?: string;
  email?: string;
  username?: string;
  address?: string;
  createdAt?: string;
  business_name?: string;
  phone?: string;
  firstname?: string;
  lastname?: string;
  expiration_date?: string;
  royaltyOnly?: boolean;
}

export const EMPTY_USER: User = {
  id: "",
  name: "",
  email: "",
  username: "",
  address: "",
  createdAt: "",
  business_name: "",
  phone: "",
  firstname: "",
  lastname: "",
  expiration_date: "",
  royaltyOnly: false,
};
