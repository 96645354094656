import React from "react";
import { useLocation } from "react-router-dom";
import cx from "classnames";
import propTypes from "prop-types";

import Button, { BUTTON_TYPES } from "../button/Button";
import MusicPlayer from "../musicPlayer";
import SearchBar from "../searchBar/SearchBar";

import SideDrawer from "../sideDrawer/SideDrawer";

import { ICONS } from "../../constants/icons.constants";
import songsStore from "../../zustand/songs.store";
import { fetchSongs } from "../../actions/songs.actions";
import musicPlayerStore from "../../zustand/musicPlayer.store";

import styles from "./appSkeleton.module.scss";
import { getFormattedSongs } from "../../zustand/general.helper";
import playlistStore from "../../zustand/playlist.store";
import { fetchCustomerData } from "../../actions/user.actions";
import { getIngoreSongIds } from "../../helpers/music.helpers";
import ignoredSongsStore from "../../zustand/ignoreSong.store";
import userStore from "../../zustand/user.store";

const AppSkeleton = (props) => {
  const { children } = props;

  const { setIsLoading, setSongs } = songsStore();
  const { setSongs: setMusicPlayerSongs, setPlayList } = musicPlayerStore();
  const { setIsLoading: setIsPlaylistFetching, setPlaylists } = playlistStore();
  const { setSongs: setIgnoredSongs } = ignoredSongsStore();
  const { user } = userStore();

  const handleRefresh = async () => {
    setIsLoading(true);
    setIsPlaylistFetching(true);
    const [songsResponse, playlistsResponse] = await Promise.allSettled([
      fetchSongs(),
      fetchCustomerData(),
    ]);
    const { value: songsData } = songsResponse;
    const { value: playlistsData } = playlistsResponse;
    const { playlists } = playlistsData || {};
    const ingoreSongIds = getIngoreSongIds(playlists);
    setIgnoredSongs(ingoreSongIds);
    setSongs(getFormattedSongs(songsData));
    setPlaylists(playlists);
    const songs = getFormattedSongs(playlists?.[0]?.songs || []);
    setMusicPlayerSongs(songs);
    setPlayList(playlists?.[0]);
    setIsLoading(false);
    setIsPlaylistFetching(false);
  };

  const currentRoute = useLocation();
  const { pathname } = currentRoute;
  const { royaltyOnly } = user || {};

  return (
    <div
      className={cx(styles.container, {
        [styles.homepage]: currentRoute.pathname === "/",
      })}
    >
      <div className={styles.bodyContainer}>
        <SideDrawer />
        <div className={styles.rightContainer}>
          {(royaltyOnly || pathname === "/") && (
            <div
              className={styles.searchBar}
              style={{
                justifyContent: !royaltyOnly && pathname === "/" ? "end" : "",
              }}
            >
              {royaltyOnly && <SearchBar />}
              {pathname === "/" && (
                <Button
                  type={BUTTON_TYPES.TERTIARY}
                  onClick={handleRefresh}
                  className={styles.btn}
                  hasIcon
                  iconType={ICONS.REFRESH}
                >
                  Refresh
                </Button>
              )}
            </div>
          )}

          <div
            style={{
              paddingTop: royaltyOnly || pathname === "/" ? 0 : "5rem",
            }}
            className={styles.childrenContainer}
          >
            {children}
          </div>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <MusicPlayer />
      </div>
    </div>
  );
};

AppSkeleton.propTypes = {
  children: propTypes.element,
};

AppSkeleton.defaultProps = {
  children: <></>,
};

export default AppSkeleton;
